export const config = {
  global_search: {
    visibility: false,
  },

  show_refresh_button: false,
  show_reset_button: false,

  pagination: true,
  pagination_info: false,
  num_of_visibile_pagination_buttons: 5,
  per_page: 10,
  per_page_options: [5, 10, 20],
  selected_rows_info: false,

  highlight_row_hover: false,
  rows_selectable: true,
  multi_column_sort: false,
  card_mode: false,
};