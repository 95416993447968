<template>
  <div class="system-page page">
    <loading :active.sync="isLoading" loader="dots"></loading>

    <div class="card search-card">
      <div class="card-body">
        <form @submit.prevent="searchPerson">
          <div class="form-row">
            <div class="col-sm-3 col-md">
              <div class="form-group">
                <label>{{ i18n("Organization.EmployeeSearch") }}</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="請輸入帳號或姓名"
                  @keydown.stop.prevent.enter="searchPerson"
                  v-model="searchKeyword"
                />
              </div>
            </div>

            <div class="col-sm-3 col-md-4">
              <button
                type="submit"
                class="btn btn-primary"
                style="margin-top: 30px; margin-right: 5px"
                @click="searchPerson"
              >
                {{ i18n("Buttons.Search") }}
              </button>
              <button
                type="button"
                class="btn btn-primary"
                style="margin-top: 30px; margin-right: 5px"
                data-toggle="modal"
                data-target="#personModal"
                @click="personModal(true)"
              >
                {{ i18n("Buttons.AddEmployee") }}
              </button>
              <button
                type="button"
                class="btn btn-info"
                style="margin-top: 30px"
                data-toggle="modal"
                data-target="#uploadModal"
              >
                匯入資料
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="content-wrap">
      <div class="tree-wrap">
        <VueTreeList
          @click="onClick"
          @drop="drop"
          :model="tree"
          v-bind:default-expanded="true"
        >
          <template v-slot:leafNameDisplay="slotProps">
            <div
              class="node"
              :class="{ 'active-node': slotProps.model.id === selectedNode }"
            >
              <div class="node-content">
                <div style="display: inline-block; vertical-align: top">
                  <div class="department-name">
                    {{ slotProps.model.DepartmentName }}
                  </div>
                  <span class="department-code">{{
                    slotProps.model.DepartmentCode
                  }}</span>
                </div>

                <span
                  class="add-folder"
                  style="margin-left: 5px"
                  @click="addDepartment(slotProps.model)"
                >
                  <eva-icon
                    width="16"
                    height="16"
                    name="plus-outline"
                  ></eva-icon>
                </span>

                <span
                  v-if="slotProps.model.pid !== 0"
                  class="edit-folder"
                  style="margin-left: 5px"
                  @click="editDepartment(slotProps.model)"
                >
                  <eva-icon
                    width="16"
                    height="16"
                    name="edit-outline"
                  ></eva-icon>
                </span>

                <span
                  v-if="
                    slotProps.model.pid !== 0 &&
                      slotProps.model.id === selectedNode
                  "
                  class="delete-folder"
                  style="margin-left: 5px"
                  @click="onDel(slotProps.model)"
                >
                  <eva-icon
                    width="16"
                    height="16"
                    name="trash-2-outline"
                  ></eva-icon>
                </span>
              </div>
            </div>
          </template>

          <span style="display: none" class="icon" slot="treeNodeIcon">🌲</span>
          <span style="display: none" class="icon" slot="addTreeNodeIcon"
            >📂</span
          >
          <span style="display: none" class="icon" slot="addLeafNodeIcon"
            >＋</span
          >
          <span style="display: none" class="icon" slot="editNodeIcon">📃</span>
          <span style="display: none" class="icon" slot="delNodeIcon">✂️</span>
        </VueTreeList>
      </div>

      <div class="department-wrap">
        <div class="yahome-table-wrap">
          <div style="text-align: right; margin-bottom: 10px"></div>

          <VueBootstrap4Table :rows="rows" :columns="columns" :config="config">
            <!-- 帳號 -->
            <template slot="account" slot-scope="props">
              <button
                class="btn btn-primary"
                type="button"
                data-toggle="modal"
                data-target="#personModal"
                @click="personModal(false, props.row)"
              >
                {{ props.row.Account }}
              </button>
            </template>

            <!-- 部門主管 -->
            <template slot="IsSupervisor" slot-scope="props">
              <el-switch
                v-model="props.row.IsSupervisor"
                active-color="#13ce66"
                inactive-color="#808080"
                @change="setSupervisor(props.row)"
              >
              </el-switch>
            </template>

            <!-- 刪除 -->
            <template slot="delete" slot-scope="props">
              <button
                type="button"
                class="btn btn-danger btn-delete btn-sm"
                data-toggle="modal"
                data-target="#delPersonModal"
                @click.capture="delModal(props.row)"
              >
                {{ i18n("Buttons.Delete") }}
              </button>
            </template>
            <template slot="empty-results"> 暫無資料 </template>
          </VueBootstrap4Table>
        </div>
      </div>
    </div>

    <!--新增&編輯部門 Modal-->
    <div
      id="departmentModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <ValidationObserver
            ref="department"
            class="modal-content"
            v-slot="{ handleSubmit, reset }"
          >
            <form
              @submit.prevent="handleSubmit(saveDepartment)"
              @reset.prevent="reset"
            >
              <div class="modal-header">
                <span v-if="isAddDepartment">
                  <span class="badge badge-primary" style="font-size: 15px">
                    {{ ParentDepartmentName }}
                  </span>
                  {{ i18n("Organization.AddDepartment") }}
                </span>
                <span v-else>{{ i18n("Organization.EditDepartment") }}</span>
              </div>
              <div class="modal-body">
                <div class="form-group">
                  <ValidationProvider
                    :name="i18n('Organization.DepartmentName')"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <label for="departmentName">
                      <span class="mark">*</span
                      >{{ i18n("Organization.DepartmentName") }}
                    </label>
                    <input
                      type="text"
                      id="departmentName"
                      class="form-control"
                      @compositionstart="is_composing = true"
                      @compositionend="is_composing = false"
                      v-model="department.DepartmentName"
                    />
                    <span v-if="errors[0] && !is_composing" class="text-danger">
                      {{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <ValidationProvider
                    :name="i18n('Organization.DepartmentCode')"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <label for="departmentCode">
                      <span class="mark">*</span
                      >{{ i18n("Organization.DepartmentCode") }}
                      <span class="mark">(不可重複)</span>
                    </label>
                    <input
                      type="text"
                      id="departmentCode"
                      class="form-control"
                      v-model="department.DepartmentCode"
                    />
                    <span v-if="errors[0]" class="text-danger">
                      {{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="reset"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  {{ i18n("Buttons.Cancel") }}
                </button>
                <button type="submit" class="btn btn-success">
                  {{ i18n("Buttons.Save") }}
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>

    <!--刪除部門 Modal-->
    <div
      id="delDepartmentModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <span>{{ i18n("Organization.DeleteDepartment") }}</span>
          </div>
          <div v-if="canDelete" class="modal-body">
            確認刪除<span style="font-weight: bolder; color: red">
              {{ department.DepartmentName }}</span
            >
            ?
          </div>
          <div v-else class="modal-body">
            若欲刪除
            <span style="font-weight: bolder; color: red">
              {{ department.DepartmentName }} </span
            >，需先刪除底下的
            <span style="font-weight: bolder; color: red">部門及人員</span>。
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              {{ i18n("Buttons.Cancel") }}
            </button>
            <button
              v-if="canDelete"
              type="button"
              class="btn btn-danger"
              @click="delDepartment"
            >
              {{ i18n("Buttons.Delete") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--新增 & 編輯 人員 Modal -->
    <div
      class="modal fade"
      id="personModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="personModal"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <ValidationObserver
          ref="person"
          class="modal-content"
          v-slot="{ handleSubmit, reset }"
        >
          <form
            @submit.prevent="handleSubmit(savePerson)"
            @reset.prevent="reset"
          >
            <div class="modal-header">
              <span v-if="isPersonAdd">{{
                i18n("Organization.AddEmployee")
              }}</span>
              <span v-else>{{ i18n("Organization.EditEmployee") }}</span>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <ValidationProvider
                  :name="i18n('Basic.Account')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label
                    ><span class="mark">*</span
                    >{{ i18n("Basic.Account") }}</label
                  >
                  <input
                    type="type"
                    name="name"
                    class="form-control"
                    :disabled="!isPersonAdd"
                    v-model="person.Account"
                  />
                  <span class="text-danger">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
              <div
                class="form-group"
                v-for="(Domain, index) in person.ADAccountMapping"
                :key="index"
              >
                <label> AD-{{ Domain.DomainName }} </label>
                <input
                  type="type"
                  name="name"
                  class="form-control"
                  v-model="Domain.Account"
                />
              </div>
              <div class="form-group">
                <ValidationProvider
                  :name="i18n('Basic.ChineseName')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label
                    ><span class="mark">*</span
                    >{{ i18n("Basic.ChineseName") }}</label
                  >
                  <input
                    type="type"
                    name="name"
                    class="form-control"
                    @compositionstart="is_composing = true"
                    @compositionend="is_composing = false"
                    v-model="person.UserName"
                  />
                  <span v-if="errors[0] && !is_composing" class="text-danger"
                    >{{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <ValidationProvider
                  rules="required|email"
                  v-slot="{ errors }"
                  name="Email"
                >
                  <label><span class="mark">*</span>Email</label>
                  <input
                    type="type"
                    name="name"
                    class="form-control"
                    v-model="person.Email"
                  />
                  <span class="text-danger">{{ errors[0] }} </span>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <ValidationProvider
                  :name="i18n('Basic.Phone_Number')"
                  rules="required|numeric"
                  v-slot="{ errors }"
                >
                  <label
                    ><span class="mark">*</span
                    >{{ i18n("Basic.Phone_Number") }}</label
                  >
                  <input
                    type="type"
                    name="name"
                    class="form-control"
                    v-model="person.PhoneNumber"
                  />
                  <span class="text-danger">{{ errors[0] }} </span>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <ValidationProvider
                  :name="i18n('Organization.Primary')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label>
                    <span class="mark">*</span
                    >{{ i18n("Organization.Primary") }}
                  </label>
                  <treeselect
                    :options="departmentList"
                    :searchable="true"
                    :show-count="true"
                    :default-expand-level="1"
                    placeholder="請選擇"
                    v-model="mainDepartment.DepartmentGuid"
                  />
                  <span v-if="errors[0]" class="text-danger">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <label
                  ><span class="mark">*</span
                  >{{ i18n("Basic.Supervisor") }}</label
                >
                <select
                  class="form-control"
                  v-model="mainDepartment.IsSupervisor"
                >
                  <option :value="true">是</option>
                  <option :value="false">否</option>
                </select>
              </div>

              <div class="form-group">
                <hr />
                <div
                  v-for="(item, index) in othersDepartment"
                  :key="index"
                  style="
                    margin-bottom: 20px;
                    display: flex;
                    justify-content: space-between;
                    font-size: 0.9em;
                  "
                >
                  <div style="flex:1;">
                    {{ i18n("Organization.DoubleAs") }}
                    <span
                      style="
                        background-color: rgb(236 241 255);
                        border-radius: 5px;
                        padding: 5px 10px;
                      "
                    >
                      {{ item.DepartmentName }}
                    </span>
                  </div>
                  <div style="margin-right:10px;">
                    {{ i18n("Basic.Supervisor") }}
                    <span
                      style="
                        background-color: rgb(236 241 255);
                        border-radius: 5px;
                        padding: 5px 10px;
                      "
                    >
                      {{ item.IsSupervisor ? "是" : "否" }}
                    </span>
                  </div>

                  <button
                    type="button"
                    class="btn btn-danger btn-sm"
                    @click="delItem(index)"
                  >
                    <eva-icon fill="#FFF" name="trash-2-outline"></eva-icon>
                  </button>
                </div>
                <div>
                  <AddDepartment
                    v-if="addOthersDepartment"
                    @addDoubleAs="saveData"
                    @addCancel="cancelItem"
                  />
                  <button
                    v-if="!addOthersDepartment"
                    type="button"
                    class="btn btn-primary btn-sm"
                    @click="addItem"
                  >
                    <i class="fas fa-plus"></i>
                    {{ i18n("Organization.DoubleAs") }}
                  </button>
                </div>
              </div>

              <div class="form-group">
                <label>{{ i18n("Basic.Role") }}</label>
                <treeselect
                  :multiple="true"
                  :options="roleList"
                  placeholder="請選擇"
                  valueFormat="object"
                  v-model="personRoles"
                />
              </div>
            </div>
            <div class="modal-footer">
              <button
                v-if="!isPersonAdd"
                type="button"
                class="btn btn-warning"
                @click="changePassword"
              >
                {{ i18n("Buttons.ChangePassword") }}
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                {{ i18n("Buttons.Cancel") }}
              </button>
              <button type="submit" class="btn btn-success">
                {{ i18n("Buttons.Save") }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>

    <!--刪除人員 Modal-->
    <div
      class="modal fade"
      id="delPersonModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="delPersonModal"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <span>{{ i18n("Organization.DeleteEmployee") }}</span>
          </div>
          <div class="modal-body">
            確認刪除
            <span style="font-weight: bolder; color: red">
              {{ person.Name }}
            </span>
            ?
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              {{ i18n("Buttons.Cancel") }}
            </button>
            <button
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
              @click="delPerson"
            >
              {{ i18n("Buttons.Delete") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- 匯入資料 Modal-->
    <div class="modal fade" id="uploadModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <span>匯入資料</span>
          </div>
          <div class="modal-body" style="margin-bottom: 20px">
            <p class="form-group">
              <u
                style="color: midnightblue; cursor: pointer;"
                @click.prevent="downloadFile"
                >點擊下載範例檔</u
              >
            </p>
            <p class="form-group">
              <span class="mark">* 檔案限制：[檔名].xlsx、[檔名].xls</span
              ><br />
              <span class="mark">* 大小限制：10MB 以下</span><br />
              <span class="mark">* 上傳前請確認是否符合格式</span><br />
            </p>
            <div class="from-group">
              <label><span class="mark">*</span>檔案</label>
              <el-upload
                ref="upload"
                action
                accept=".xls,.xlsx"
                :auto-upload="false"
                :show-file-list="false"
                :on-change="uploadFile"
                :limit="1"
              >
                <el-button size="small" type="primary">點擊上傳</el-button>
              </el-upload>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              <eva-icon name="close-outline" width="15" height="15" fill="#FFF"></eva-icon>
            </button>
            <button type="button" class="btn btn-primary" data-dismiss="modal">
              <eva-icon name="checkmark-outline" width="15" height="15" fill="#FFF"></eva-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- API 回應訊息 -->
    <ResponseDataModal
      :successfulList="successMessage"
      :failedList="failMessage"
    />
  </div>
</template>

<script>
/* global $ */
import { VueTreeList, Tree } from "vue-tree-list";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

import ResponseDataModal from "@/components/back/Modal/ResponseData";

import AddDepartment from "@/components/back/AddDepartment.vue";
import { config } from "@/components/back/table_config.js";
import { mapGetters } from "vuex";

export default {
  name: "Department",

  components: {
    VueTreeList,
    Treeselect,
    AddDepartment,
    ResponseDataModal
  },
  data() {
    return {
      //AD
      domainlist: [],
      // compositionstart& compositionend
      is_composing: false,

      uploadAPI: `${process.env.VUE_APP_API}`,

      // 匯入資料
      excelFile: null,
      // API 回應訊息
      successMessage: [], // 成功訊息
      failMessage: [], // 失敗訊息

      // 角色
      roleList: [], // 列表
      personRoles: [],

      // 部門
      departmentList: [], // 列表
      selectedNode: null, // 目前點擊的Node
      selectedDepartmentGuid: null, // 目前點擊的部門Guid
      isAddDepartment: true,
      canDelete: false,

      // 人員
      searchKeyword: null,
      isPersonAdd: false,
      mainDepartment: {}, // 主要部門
      // 選取兼任部門
      addOthersDepartment: false,
      othersDepartment: [], // 兼任部門

      // data
      person: {},

      // 部門(Tree)
      tree: new Tree([]), // 部門
      rootGuid: null, // 主部門Guid

      ParentDepartmentName: null, // 上層部門名稱
      department: {}, // 部門Data

      // 人員 table
      rows: [],
      columns: [
        {
          label: this.i18n("Basic.Account"),
          name: "Account",
          slot_name: "account",
        },
        {
          label: this.i18n("Basic.ChineseName"),
          name: "Name",
        },
        {
          label: "Email",
          name: "Email",
        },
        {
          label: this.i18n("Basic.Phone_Number"),
          name: "PhoneNumber",
        },
        {
          label: this.i18n("Basic.Supervisor"),
          name: "IsSupervisor",
          slot_name: "IsSupervisor",
        },
        {
          label: "",
          slot_name: "delete",
        },
      ],
      config,
    };
  },

  computed: {
    ...mapGetters(["lang"]),
    isLoading() {
      return this.$store.state.isLoading;
    },
  },

  watch: {
    lang: function() {
      this.columns = [
        {
          label: this.i18n("Basic.Account"),
          name: "Account",
          slot_name: "account",
        },
        { label: this.i18n("Basic.ChineseName"), name: "Name" },
        { label: "Email", name: "Email" },
        { label: this.i18n("Basic.Phone_Number"), name: "PhoneNumber" },
        {
          label: this.i18n("Basic.Supervisor"),
          name: "IsSupervisor",
          slot_name: "IsSupervisor",
        },
        { label: "", slot_name: "delete" },
      ];
    },
  },

  methods: {
    // 多國語系
    i18n(keyStr) {
      return this.$t(keyStr);
    },

    // 下載範例檔
    downloadFile() {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/BasicInformation/DepartmentTemplateDownload`;

      vm.$http.get(api, { responseType: "blob" }).then((response) => {
        let blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "組織範本.xlsx";
        a.click();
        window.URL.revokeObjectURL(url);
      });
    },

    // uploadModal() {
    //   $("#uploadModal").modal("show");
    // },

    // 匯入資料 API
    uploadFile(file) {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/BasicInformation/Upload`;

      let fd = new FormData();
      fd.append("file", file.raw);

      $("#uploadModal").modal("hide");
      vm.$store.dispatch("updateLoading", true);

      vm.$http
        .post(api, fd)
        .then((response) => {
          if (response.status === 200) {
            vm.$store.dispatch("updateLoading", false);
            vm.successMessage = response.data.filter((item) => {
              return item.indexOf("成功") !== -1;
            });
            vm.failMessage = response.data.filter((item) => {
              return item.indexOf("失敗") !== -1;
            });
            vm.$store.commit("OpenResponseDataModal", true);
            vm.$refs.upload.clearFiles();
            console.log(response.data);
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$refs.upload.clearFiles();
          vm.$notify({
            title: "失敗",
            message: error.response.data,
            type: "error",
            duration: 3500,
          });
        });
    },

    ///////////////////////////  取得部門 API  ///////////////////////////////////////////
    getDepartments() {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/BasicInformation/department`;

      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .get(api)
        .then((response) => {
          if (response.status === 200) {
            vm.departmentList = response.data;
            vm.tree = new Tree([
              {
                DepartmentGuid: response.data[0].DepartmentGuid,
                DepartmentCode: response.data[0].DepartmentCode,
                DepartmentName: response.data[0].DepartmentName,
                ParentDepartmentGuid: response.data[0].ParentDepartmentGuid,
                delNodeDisabled: true,
                dragDisabled: true,
                children: response.data[0].children,
              },
            ]);
            vm.rootGuid = response.data[0].DepartmentGuid;
            vm.$nextTick(function() {
              vm.$store.dispatch("updateLoading", false);
            });
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$notify({
            title: "失敗",
            message: error.response.data,
            type: "error",
            duration: 3500,
          });
        });
    },

    ///////////////////////////  取得角色 API  ///////////////////////////////////////////
    getRoles() {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/auth/role`;
      const params = {
        name: null,
      };

      vm.$http
        .get(api, { params })
        .then((response) => {
          if (response.status === 200) {
            response.data.forEach((item) => {
              let role = {
                id: item.Id,
                label: item.Name,
              };
              vm.roleList.push(role);
            });
          }
        })
        .catch((error) => {
          vm.$notify({
            title: "失敗",
            message: error.response.data,
            type: "error",
            duration: 3500,
          });
        });
    },

    ///////////////////////////  查詢人員 API  ///////////////////////////////////////////
    searchPerson() {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/auth/backgrounduser`;

      vm.selectedNode = null;
      vm.selectedDepartmentGuid = null;

      const params = {
        keyword: vm.searchKeyword,
      };

      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .get(api, { params })
        .then((response) => {
          if (response.status === 200) {
            vm.rows = response.data;
            vm.$nextTick(function() {
              vm.$store.dispatch("updateLoading", false);
            });
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$notify({
            title: "失敗",
            message: error.response.data,
            type: "error",
            duration: 3500,
          });
        });
    },

    ///////////////////////////  人員 新增&編輯 Modal ///////////////////////////////////////////
    personModal(isAdd, item) {
      if (!isAdd) {
        this.getPersonDepartment(item.Account);
        this.getPersonRole(item.Account);
        this.person = {
          Account: item.Account,
          UserName: item.Name,
          Email: item.Email,
          PhoneNumber: item.PhoneNumber,
        };


        //ad處理-----start
        const commonDomainArr = JSON.parse(JSON.stringify(this.domainlist));
        const userDomainArr = item.ADAccountMapping;

        let mergeByName = (a1, a2) =>
          a1.map((itm) => ({
            ...a2.find((item) => {
              if (item.DomainName === itm.DomainName) {
                itm.Account = item.Account;
              }
            }),
            ...itm,
          }));

        const resultDomainArr = mergeByName(commonDomainArr, userDomainArr);
        this.person.ADAccountMapping = resultDomainArr;
        //ad處理------end

        this.isPersonAdd = false;
      } else {
        this.person = {};

        //ad處理-----start
        const commonDomainArr = JSON.parse(JSON.stringify(this.domainlist));
        this.person.ADAccountMapping = commonDomainArr;
        //ad處理------end

        this.mainDepartment = {
          Seq: 0,
          DepartmentGuid: null,
          IsSupervisor: false,
        };
        this.personRoles = [];
        this.othersDepartment = [];
        this.isPersonAdd = true;
      }
      this.addOthersDepartment = false;
      this.$refs.person.reset();
    },

    getDomainList() {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/auth/domainlist`;

      vm.$http
        .get(api)
        .then((response) => {
          if (response.status === 200) {
            response.data.forEach((item) => {
              vm.domainlist.push({
                DomainName: item,
                Account: null,
              });
            });

            
          }
        })
        .catch((error) => {
          vm.$bus.$emit("message: push", error.response.data, "danger");
        });
    },

    // 取得人員歸屬部門
    getPersonDepartment(item) {
      const vm = this;
      const account = item;
      const api = `${process.env.VUE_APP_API}/auth/backgrounduser/department/${account}`;

      vm.$http
        .get(api)
        .then((response) => {
          if (response.status === 200) {
            vm.othersDepartment = [];
            response.data.forEach((item) => {
              if (item.Seq === 0) {
                vm.mainDepartment = {
                  DepartmentGuid: item.DepartmentGuid,
                  IsSupervisor: item.IsSupervisor,
                  Seq: item.Seq,
                };
              } else {
                vm.othersDepartment.push(item);
              }
            });
            return;
          }
        })
        .catch((error) => {
          vm.$notify({
            title: "失敗",
            message: error.response.data,
            type: "error",
            duration: 3500,
          });
        });
    },

    // 取得人員擁有角色
    getPersonRole(item) {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/auth/backgrounduser/roles/${item}`;

      vm.$http
        .get(api)
        .then((response) => {
          if (response.status === 200) {
            vm.personRoles = response.data;
            return;
          }
        })
        .catch((error) => {
          vm.$notify({
            title: "失敗",
            message: error.response.data,
            type: "error",
            duration: 3500,
          });
        });
    },

    // 新增兼任部門
    addItem() {
      this.addOthersDepartment = true;
    },
    // 取消新增兼任部門
    cancelItem() {
      this.addOthersDepartment = false;
    },
    // 刪除兼任部門
    delItem(id) {
      this.othersDepartment.splice(id, 1);
    },
    // 儲存兼任部門
    saveData(item) {
      this.addOthersDepartment = false;
      this.othersDepartment.push({ ...item });
    },

    ///////////////////////////  人員 新增&編輯 API ///////////////////////////////////////////
    savePerson() {
      const vm = this;

      let api = `${process.env.VUE_APP_API}/auth/backgrounduser`;
      let httpMethod = "post";
      let message = "人員新增成功";

      if (!vm.isPersonAdd) {
        httpMethod = "put";
        message = "人員編輯成功";
      }

      // 兼任部門
      let othersDepartment = [];
      if (vm.othersDepartment.length > 0) {
        vm.othersDepartment.map((item, index) => {
          let data = {
            Seq: ++index,
            DepartmentGuid: item.DepartmentGuid,
            IsSupervisor: item.IsSupervisor,
          };
          othersDepartment.push(data);
        });
      }

      const data = {
        ...vm.person,
        Departments: [vm.mainDepartment, ...othersDepartment],
        Roles: vm.personRoles,
      };

      $("#personModal").modal("hide");
      vm.$store.dispatch("updateLoading", true);

      vm.$http[httpMethod](api, data)
        .then((response) => {
          if (response.status === 200) {
            vm.selectedNode = vm.mainDepartment.DepartmentGuid;
            vm.selectedDepartmentGuid = vm.mainDepartment.DepartmentGuid;
            vm.$store.dispatch("updateLoading", false);
            vm.$notify({
              title: "成功",
              message: message,
              type: "success",
              duration: 2000,
            });
            vm.$nextTick(function() {
              vm.getPerson();
            });
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$notify({
            title: "失敗",
            message: error.response.data,
            type: "error",
            duration: 3500,
          });
        });
    },

    ///////////////////////   設定主管 API  //////////////////////////////////
    setSupervisor(item) {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/BasicInformation/department/setsupervisor`;

      const data = {
        UserId: item.UserId,
        DepartmentGuid: item.DepartmentGuid,
        IsSupervisor: item.IsSupervisor,
      };

      vm.$http
        .post(api, data)
        .then((response) => {
          if (response.status === 200) {
            vm.$notify({
              title: "成功",
              message: "設定成功",
              type: "success",
              duration: 2000,
            });
            vm.getPerson();
          }
        })
        .catch((error) => {
          vm.$notify({
            title: "失敗",
            message: error.response.data,
            type: "error",
            duration: 3500,
          });
        });
    },

    ///////////////////////////////   部門  /////////////////////////////////////

    // 新增部門 Modal
    addDepartment(item) {
      this.isAddDepartment = true;
      this.ParentDepartmentName = item.DepartmentName;
      this.department = {
        ParentDepartmentGuid: item.DepartmentGuid,
      };
      this.$refs.department.reset();
      $("#departmentModal").modal("show");
    },

    // 編輯部門 Modal
    editDepartment(item) {
      this.isAddDepartment = false;
      this.department = {
        DepartmentGuid: item.DepartmentGuid,
        DepartmentCode: item.DepartmentCode,
        DepartmentName: item.DepartmentName,
        ParentDepartmentGuid: item.ParentDepartmentGuid,
      };
      if (item.pid === 0) {
        this.department.ParentDepartmentGuid = null;
      }
      this.$refs.department.reset();
      $("#departmentModal").modal("show");
    },

    ///////////////////////////   部門 新增&編輯 API  /////////////////////////////////////////
    saveDepartment() {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/BasicInformation/department`;

      let httpMethod = "post";
      let message = "部門新增成功";

      if (!vm.isAddDepartment) {
        httpMethod = "put";
        message = "部門編輯成功";
      }

      const data = { ...vm.department };
      $("#departmentModal").modal("hide");

      this.$nextTick(function() {
        vm.$store.dispatch("updateLoading", true);

        vm.$http[httpMethod](api, data)
          .then((response) => {
            if (response.status === 200) {
              vm.$store.dispatch("updateLoading", false);
              vm.$notify({
                title: "成功",
                message: message,
                type: "success",
                duration: 2000,
              });
              vm.getDepartments();
            }
          })
          .catch((error) => {
            vm.$store.dispatch("updateLoading", false);
            vm.$notify({
              title: "失敗",
              message: error.response.data,
              type: "error",
              duration: 3500,
            });
          });
      });
    },

    ///////////////////////////  拖拉部門 API  /////////////////////////////////////////
    drop(e) {
      if (e.node === e.target) {
        return;
      }
      const vm = this;
      const api = `${process.env.VUE_APP_API}/BasicInformation/department`;

      const data = {
        DepartmentGuid: e.node.DepartmentGuid,
        DepartmentCode: e.node.DepartmentCode,
        DepartmentName: e.node.DepartmentName,
        ParentDepartmentGuid: e.target.DepartmentGuid,
      };

      vm.$http
        .put(api, data)
        .then((response) => {
          if (response.status === 200) {
            vm.$notify({
              title: "成功",
              message: "部門移動成功",
              type: "success",
              duration: 2000,
            });
            vm.getDepartments();
          }
        })
        .catch((error) => {
          vm.$notify({
            title: "失敗",
            message: error.response.data,
            type: "error",
            duration: 3500,
          });
        });
    },

    // 刪除部門 Modal
    onDel(node) {
      this.department = {
        DepartmentGuid: node.DepartmentGuid,
        DepartmentName: node.DepartmentName,
      };
      if (node.children === null && this.rows.length === 0) {
        this.canDelete = true;
      }
      $("#delDepartmentModal").modal("show");
    },

    // 刪除部門 API
    delDepartment() {
      const vm = this;
      const DepartmentGuid = vm.department.DepartmentGuid;
      const api = `${process.env.VUE_APP_API}/BasicInformation/department/${DepartmentGuid}`;

      $("#delDepartmentModal").modal("hide");
      vm.$store.dispatch("updateLoading", true);

      vm.$http
        .delete(api)
        .then((response) => {
          if (response.status === 200) {
            vm.$store.dispatch("updateLoading", false);
            vm.$notify({
              title: "成功",
              message: "部門刪除成功",
              type: "success",
              duration: 2000,
            });
            vm.getDepartments();
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$notify({
            title: "失敗",
            message: error.response.data,
            type: "error",
            duration: 3500,
          });
        });
    },

    // 點擊部門
    onClick(node) {
      this.selectedNode = node.id;
      this.selectedDepartmentGuid = node.DepartmentGuid;
      this.DepartmentName = node.DepartmentName;
      this.getPerson();
    },

    // 取得當前部門內人員
    getPerson() {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/BasicInformation/department/user/${vm.selectedDepartmentGuid}`;

      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .get(api)
        .then((response) => {
          if (response.status === 200) {
            vm.$store.dispatch("updateLoading", false);
            vm.rows = response.data;
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$notify({
            title: "失敗",
            message: error.response.data,
            type: "error",
            duration: 3500,
          });
        });
    },

    // 變更密碼 API
    changePassword() {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/auth/user/ChangePasswordByAdmin/${vm.person.Account}`;

      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .post(api)
        .then((response) => {
          if (response.status === 200) {
            vm.$store.dispatch("updateLoading", false);
            vm.$notify({
              title: "成功",
              message: "已發送變更密碼信件",
              type: "success",
              duration: 2000,
            });
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$notify({
            title: "失敗",
            message: error.response.data,
            type: "error",
            duration: 3500,
          });
        });
    },

    /////////////////////////////  刪除人員  /////////////////////////////////////

    // 刪除人員 Modal
    delModal(item) {
      this.person = {
        Name: item.Name,
        Account: item.Account,
      };
    },

    // 刪除部門內人員 API
    delPerson() {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/auth/backgrounduser/${vm.person.Account}`;

      vm.$http
        .delete(api)
        .then((response) => {
          if (response.status === 200) {
            vm.$notify({
              title: "成功",
              message: "人員刪除成功",
              type: "success",
              duration: 2000,
            });
            vm.selectedDepartmentGuid === null
              ? vm.searchPerson()
              : vm.getPerson();
          }
        })
        .catch((error) => {
          vm.$notify({
            title: "失敗",
            message: error.response.data,
            type: "error",
            duration: 3500,
          });
        });
    },
  },

  created() {
    this.getDepartments();
    this.getRoles();
    this.getDomainList();
  },

  destroyed() {
    $(".modal").modal("hide");
    $("body").removeClass("modal-open");
    $(".modal-backdrop").remove();
    this.$notify.close();
  },
};
</script>

<style lang="scss" scoped>
.vtl {
  .vtl-drag-disabled {
    background-color: #d0cfcf;
    &:hover {
      background-color: #d0cfcf;
    }
  }
  .vtl-disabled {
    background-color: #d0cfcf;
  }
}

.vtl-tree-node {
  cursor: move;
}
.vtl-node-main {
  padding: 0;
  transition: all 0.2s !important;
  &:hover {
    background-color: transparent !important;
  }
}

.vtl .vtl-drag-disabled {
  background-color: transparent;
}

.vtl-border.vtl-up {
  margin-top: 0px;
}

.vtl-border {
  height: 0;
}

.department-name {
  font-size: 1.1em;
}
.department-code {
  font-size: 0.9em;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 0 10px;
}

.department-edit-card {
  padding: 20px;
  text-align: right;
  background-color: #fff;
  border-radius: 5px;
  padding-bottom: 0;
  input {
    background-color: #f4f6ff;
    border: none;
    line-height: 34px;
    padding: 0 15px;
    margin-right: 10px;
    vertical-align: middle;
    border-radius: 5px;
  }
}

.content-wrap {
  display: flex;
  height: calc(100vh - 160px);
  .tree-wrap {
    margin-right: 10px;
    background-color: #fff;
    padding: 30px;
    border-radius: 5px;
    width: 500px;
    overflow: auto;

    & > .vtl {
      border: none;
    }

    .edit-folder,
    .add-folder {
      background: #d7e6ff;
      display: inline-block;
      width: 25px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      border-radius: 50%;
      cursor: pointer;
      i {
        opacity: 0.7;
      }
    }
    .delete-folder {
      background: #f77373;
      display: inline-block;
      width: 25px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      border-radius: 3px;
      margin-left: 3px;
      border-radius: 50%;
      cursor: pointer;
      opacity: 0.7;
    }
  }

  .department-wrap {
    flex: 1;
    min-width: 1px;
    background-color: #fff;
  }
}

.node {
  padding: 5px;
  font-size: 0.8em;
  white-space: nowrap;

  .node-content {
    padding: 5px;
    border-left: #fff 5px solid;
  }

  &.active-node {
    .node-content {
      background-color: #f7f7f7;
      border-left: #303c50 5px solid;
      display: block;
    }

    .department-code {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
}

.graypic {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
}

.mark {
    background-color: inherit;
    color: red;
}
</style>
