<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(clickAdd)">
        <div class="form-group">
          <label><span class="mark">*</span>兼任部門</label>
          <ValidationProvider
            tag="section"
            name="兼任部門"
            rules="required"
            v-slot="{ errors }"
          >
            <treeselect
              :options="departmentOptions"
              placeholder="請選擇"
              value-format="object"
              v-model="department"
            />
            <span class="text-danger">{{ errors[0] }} </span>
          </ValidationProvider>
        </div>

        <div class="form-group w-100">
          <label><span class="mark">*</span>是否為此部門主管</label>
          <ValidationProvider
            tag="section"
            name="是否為此部門主管"
            rules="required"
            v-slot="{ errors }"
          >
            <select class="form-control" v-model="IsSupervisor">
              <option :value="null" disabled>請選擇</option>
              <option :value="true">是</option>
              <option :value="false">否</option>
            </select>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <button
          type="button"
          class="btn btn-secondary btn-sm"
          style="margin-left: 6px; margin-right: 10px"
          @click="clickCancel"
        >
          取消
        </button>
        <button type="submit" class="btn btn-primary btn-sm">
          儲存
        </button>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "AddDepartment",
  data() {
    return {
      // 部門List
      departmentOptions: [],
      // 選取的部門
      department: null,
      IsSupervisor: null,
    };
  },
  components: {
    Treeselect,
  },

  methods: {
    // 取得部門 API
    getDepartment() {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/BasicInformation/department`;

      vm.$http
        .get(api)
        .then((response) => {
          if (response.status === 200) {
            vm.departmentOptions = [];
            vm.departmentOptions = response.data;
          }
        })
        .catch((error) => {
          vm.$bus.$emit("message: push", error.response.data, "danger");
        });
    },

    clickCancel() {
      this.$emit("addCancel");
    },
    clickAdd() {
      this.department.IsSupervisor = this.IsSupervisor;
      this.$emit("addDepartment", this.department);
    },
  },

  created() {
    this.getDepartment();
  },
};
</script>
